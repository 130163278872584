export const environment = {
  type: 'production',
  production: true,
  enableBrowserLogs: true,
  enableRUM: true,
  dummyAuth: false,
  dataImportApiBaseUrl: 'https://api.di.hrm.visma.net',
  wsBaseUrl: 'wss://ws.di.hrm.visma.net',
  ldClientSideID: '608646fe68cb1c0b9fe4761e',
  signinUrl: 'https://signin.visma.net/loginwebapp',
  loginRedirectUrl: 'https://di.hrm.visma.net',
  wootricAccountToken: 'NPS-EU-2afb00ff',
  vismaHomeUrl: 'https://home.visma.com/',
  vismaEmpmanUrl: 'https://web.employeecore1.hrm.visma.net/uiempmanapp/',
  vismaAboutUrl: 'https://wagerun.cj.hrm.visma.net/',
  vismaCalendarUrl: 'https://web.calendar.hrm.visma.net/',
  empManModuleId: 16208,
  payrollUiUrl: 'https://payrollui.no.visma.net/',
  payrollPortal:
    'https://web.calendar.hrm.visma.net/absence/_Svelte/dist/scripts/es/ClientPortalComponentProvider.js',
  walkmeSrc:
    'https://eu-cdn.walkme.com/users/156f0c8fa19a4b7cbd7437d888ce43cc/walkme_private_156f0c8fa19a4b7cbd7437d888ce43cc_https.js',
  walkmeIntegrity: 'sha256-bLZ0I5NX1aRtz99HlrpFoeWVRLOlAerodXrCyWe5RvA=',
  wagerunUrl: 'https://wagerun.cj.hrm.visma.net',
};
